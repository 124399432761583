/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BadRequeastResponse,
  EmptyResponse,
  ProxyRequest,
  SuccessResponse,
  UnprocessedEntityResponse,
  UserSubscribeRequest
} from './data-contracts';
import {ContentType, HttpClient, RequestParams} from './http-client';

export class V1<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description subscribe user
   *
   * @name SubscribeUserPostV1ApiUsersSubscribe
   * @summary subscribeUser
   * @request POST:/v1/api/users/subscribe
   */
  subscribeUserPostV1ApiUsersSubscribe = (
    body: UserSubscribeRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      SuccessResponse,
      BadRequeastResponse | UnprocessedEntityResponse | void
    >({
      path: `/v1/api/users/subscribe`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @name ProxyPostV1ApiProxyWarmup
   * @summary proxy
   * @request POST:/v1/api/proxy/warmup
   */
  proxyPostV1ApiProxyWarmup = (
    body: ProxyRequest,
    params: RequestParams = {}
  ) =>
    this.request<EmptyResponse, void>({
      path: `/v1/api/proxy/warmup`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
