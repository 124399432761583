// Loading.tsx
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import loadingGif from './loading.gif';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(255, 255, 255, 0.8)' // This could be adjusted or removed based on your needs
  },
  image: {
    maxWidth: '100%'
  }
}));

const CLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={loadingGif} alt='Loading...' className={classes.image} />
    </div>
  );
};

export default CLoading;
