import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { joinWaitlist, warmupSubscriberUser } from '../actions/Waitlist.action';
import { RootState } from '../reducers';

interface WaitlistContextValue {
  join: (email: string) => void;
  warmup:() => void;
  error: string | null;
  success: boolean;
  warmupSuccess: boolean;
}

const WaitlistContext = createContext<{
  join:(email: string) => void;
  warmup:() => void;
  error: string | null;
  success: boolean;
  warmupSuccess: boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  join: async () => false,
  warmup: async () => false,
  error: null,
  success: false,
  warmupSuccess: false
});

interface WaitlistProviderProps {
  children: React.ReactNode;
  baseApi: string;
}
const WaitlistProvider: React.FC<WaitlistProviderProps> = ({ children, baseApi }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  const error = useSelector((state: RootState) => state.waitlist.error);
  const success = useSelector((state: RootState) => state.waitlist.success);
  const warmupSuccess = useSelector((state: RootState) => state.waitlist.warmupSuccess);

  const join = async (email: string) => {
    await dispatch(joinWaitlist(email, baseApi));
  };

  const warmup = async () => {
    await dispatch(warmupSubscriberUser(baseApi));
  };

  return (
    <WaitlistContext.Provider value={{ join, warmup, error, success, warmupSuccess }}>
      {children}
    </WaitlistContext.Provider>
  );
};

export const useWaitlist = (): WaitlistContextValue => {
  const context = useContext(WaitlistContext);
  if (!context) {
    throw new Error('useWaitlist must be used within a WaitlistProvider');
  }
  return context;
};

export default WaitlistProvider;
