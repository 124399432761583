import {
  JOIN_WAITLIST_REQUEST,
  JOIN_WAITLIST_SUCCESS,
  JOIN_WAITLIST_FAILURE,
  WARMUP_REQUEST,
  WARMUP_SUCCESS,
  WARMUP_FAILURE,
  ActionTypes
} from '../actions/Waitlist.action';

export interface WaitlistState {
  loading: boolean;
  success: boolean;
  warmupSuccess: boolean;
  error: string | null;
}

const initialState: WaitlistState = {
  loading: false,
  success: false,
  warmupSuccess: false,
  error: null
};

export const waitlistReducer = (
  state = initialState,
  action: ActionTypes
): WaitlistState => {
  switch(action.type) {
    case JOIN_WAITLIST_REQUEST:
    case WARMUP_REQUEST:
      return { ...state, loading: true, error: null };
    case JOIN_WAITLIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case WARMUP_SUCCESS:
      return { ...state, loading: false, warmupSuccess: true };
    case JOIN_WAITLIST_FAILURE:
    case WARMUP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

