import { Dispatch } from 'redux';
import { postSubscribe, postWarmupPostSubscribe } from '../../integrations/api/subscribe';

export const JOIN_WAITLIST_REQUEST = 'JOIN_WAITLIST_REQUEST';
export const JOIN_WAITLIST_SUCCESS = 'JOIN_WAITLIST_SUCCESS';
export const JOIN_WAITLIST_FAILURE = 'JOIN_WAITLIST_FAILURE';
export const WARMUP_REQUEST = 'WARMUP_REQUEST';
export const WARMUP_SUCCESS = 'WARMUP_SUCCESS';
export const WARMUP_FAILURE = 'WARMUP_FAILURE';

interface WarmupRequestAction {
  type: typeof WARMUP_REQUEST;
}

interface WarmupSuccessAction {
  type: typeof WARMUP_SUCCESS;
}

interface WarmupFailureAction {
  type: typeof WARMUP_FAILURE;
  payload: string;
}

export type WarmupActionTypes = WarmupRequestAction | WarmupSuccessAction | WarmupFailureAction;

export const warmupRequest = (): WarmupRequestAction => {
  return {
    type: WARMUP_REQUEST
  };
};

export const warmupSuccess = (): WarmupSuccessAction => {
  return {
    type: WARMUP_SUCCESS
  };
};

export const warmupFailure = (error: string): WarmupFailureAction => {
  return {
    type: WARMUP_FAILURE,
    payload: error
  };
};

interface JoinWaitlistRequestAction {
  type: typeof JOIN_WAITLIST_REQUEST;
}

interface JoinWaitlistSuccessAction {
  type: typeof JOIN_WAITLIST_SUCCESS;
  payload: { success: boolean }
}

interface JoinWaitlistFailureAction {
  type: typeof JOIN_WAITLIST_FAILURE;
  payload: string;
}

export type WaitlistActionTypes =
  | JoinWaitlistRequestAction
  | JoinWaitlistSuccessAction
  | JoinWaitlistFailureAction;

export type ActionTypes = WaitlistActionTypes | WarmupActionTypes;

export const joinWaitlistRequest = (): JoinWaitlistRequestAction => {
  return {
    type: JOIN_WAITLIST_REQUEST
  };
};

export const joinWaitlistSuccess = (response: { success: boolean }): JoinWaitlistSuccessAction => {
  return {
    type: JOIN_WAITLIST_SUCCESS,
    payload: response
  };
};

export const joinWaitlistFailure = (error: string): JoinWaitlistFailureAction => {
  return {
    type: JOIN_WAITLIST_FAILURE,
    payload: error
  };
};

export const joinWaitlist = (email: string, baseApiUrl: string) => async (dispatch: Dispatch<ActionTypes>) => {
  dispatch(joinWaitlistRequest());
  try {
    const response = await postSubscribe({ email }, baseApiUrl);
    dispatch(joinWaitlistSuccess(response));
  }
 catch(error: any) {
  if ('response' in error && error.response?.data?.error) {
    dispatch(joinWaitlistFailure(error.response.data.error));
  }
  else if (error instanceof Error) {
    dispatch(joinWaitlistFailure(error.message));
  }
  else {
    dispatch(joinWaitlistFailure('An unknown error occurred.'));
  }
 }
};

export const warmupSubscriberUser = (
  baseApiUrl: string
) => async (dispatch: Dispatch<ActionTypes>) => {
  dispatch(warmupRequest());
  try {
    await postWarmupPostSubscribe({ actionName: 'subscribeUser' }, baseApiUrl);
    dispatch(warmupSuccess());
  }
 catch(error: any) {
    if (error instanceof Error) {
      dispatch(warmupFailure(error.message));
    }
 else {
      dispatch(warmupFailure('An unknown error occurred.'));
    }
  }
};
