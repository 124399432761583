import React, { ReactElement, Suspense } from 'react';
import { cTheme } from '@magic-poseidon/common-ui/src/core';
import { Provider } from 'react-redux';
import WaitlistProvider from '@magic-poseidon/common-ui/src/data/context/Waitlist.context';
import store from '@magic-poseidon/common-ui/src/data/store';
import { initializeAnalytics } from '@magic-poseidon/common-ui/src/integrations/events';
import { I18nextProvider } from 'react-i18next';
import i18n from '@magic-poseidon/common-ui/src/core/i18n';
import Loading from '@magic-poseidon/common-ui/src/components/CLoading/CLoading.component';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

interface ThemeProps {
  element: ReactElement;
}

initializeAnalytics(process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID || '');

const Theme: React.FC<ThemeProps> = ({ element }) => (
  <Provider store={store}>
    <WaitlistProvider baseApi={process.env.GATSBY_API_URL || ''}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={cTheme}>
          <CssBaseline />
          <Suspense fallback={<Loading />}>
            {element}
          </Suspense>
        </ThemeProvider>
      </I18nextProvider>
    </WaitlistProvider>
  </Provider>
);

export default Theme;
