import { combineReducers } from 'redux';
import { waitlistReducer, WaitlistState } from './Waitlist.reducer';

export interface RootState {
  waitlist: WaitlistState;
}

const rootReducer = combineReducers({
  waitlist: waitlistReducer
});

export default rootReducer;
