import {
  createTheme,
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions
} from '@material-ui/core/styles';
import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette';

interface HeadingStyle {
  [key: string]: any;
  color: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
}

interface BodyStyle {
  [key: string]: any;
  color: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
  letterSpacing: number;
}

type CbBlack = {
  main: string;
  light: string;
}

type cbWhiteTransparent = {
  main: string;
  light: string;
  dark: string;
}

interface CustomPaletteOptions extends PaletteOptions {
  cbBlack?: CbBlack,
  cbWhiteTransparent?: cbWhiteTransparent
}

interface CustomPalette extends Palette {
  cbBlack?: CbBlack,
  cbWhiteTransparent?: cbWhiteTransparent
}

export interface CustomTheme extends MuiTheme {
  palette: CustomPalette;
}

interface CustomThemeOptions extends MuiThemeOptions {
  palette: CustomPaletteOptions;
}

const makeHeading = (fontSizePx: number): HeadingStyle => ({
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: `${fontSizePx}px`,
  fontWeight: 300,
  lineHeight: 1
});

const makeBody = (fontSizePx: number, lineHeight = 1): BodyStyle => ({
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: `${fontSizePx}px`,
  fontWeight: 400,
  lineHeight,
  letterSpacing: 0
});

const themeOptions: CustomThemeOptions = {
  palette: {
    background: {
      default: '#0d0e12'
    },
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#75767a'
    },
    success: {
      main: '#00DC6A'
    },
    error: {
      main: '#FF4343',
      dark: '#FF3528'
    },
    cbBlack: {
      main: '#000',
      light: '#1C1F26'
    },
    cbWhiteTransparent: {
      main: 'rgba(255, 255, 255, 0.7)',
      light: 'rgba(229, 235, 241, 0.1)',
      dark: 'rgba(255, 255, 255, 0.5)'
    },
    common: {
      white: '#FFF',
      black: '#000'
    }
  },
  typography: {
    h1: makeHeading(62),
    h2: makeHeading(52),
    h3: makeHeading(36),
    h4: makeHeading(24),
    h5: makeHeading(20),
    h6: makeHeading(16),
    body1: makeBody(16),
    body2: makeBody(16, 1.4)
  }
};

const cTheme: CustomTheme = createTheme(themeOptions);

export { cTheme };
